<template>
  <div class="container-app">
    <div class="questions">
      <Header active_tab="revision" />
      <div class="prev-made-questions">
        <ol type="1">
          <li v-for="(question, index) in questions" :key="index">
            <div class="row" justify-content-between>
              <div class="col my-2 question">{{ question.question }}</div>
              <div class="col-1">({{ question.marks }})</div>
            </div>
            <ol v-if="question.propositions" type="A">
              <li v-for="(item, ind) in question.propositions" :key="ind">
                {{ item["props"] }}
              </li>
            </ol>
            <div
              v-if="question.correct_option"
              :id="'answer' + index"
              style="display: none"
            >
              {{ question.correct_option }}
            </div>
            <div v-else :id="'answer' + index" style="display: none">
              {{ question.answer }}
            </div>
            <button
              class="btn btn-dark my-3 mx-2"
              @click="showAnswer(index, $event)"
            >
              Show Answer
            </button>
          </li>
        </ol>
      </div>
      <div class="justify-content-center d-flex my-5">
        <button class="btn btn-success" @click="redirect">Home</button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import store from "@/store";
import Header from "@/components/Header";
export default {
  components: { Header },
  props: ["revision_id"],

  mounted() {
    this.revision_id;
    //For this test-id set questions
    var config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };

    axios
      .post(
        "https://Pysberduck.pythonanywhere.com/api/edit-revision",
        {
          revision_id: this.revision_id,
        },
        config
      )
      .then(
        (res) => {
          res;
          if (res.data["status"] == "200") {
            this.questions = res.data["data"]; //Questions List
            this.questions.length;
          } else if (res.data == "500") swal("Test Not made yet");
          else if (res.data == "400") swal("Test does not exist");
          else swal("Some error occured");
        },
        (err) => {
          swal("Some error occured");
        }
      );
    store.state.loading = false;
  },
  data() {
    return {
      selected_option: -1,
      title: "",
      start_time: "",
      end_time: "",
      edit_open: false,
      mcq: true,
      current_question: "",
      current_options: ["", "", "", ""],
      correct_option: "",
      marks: -1,
      answer: "",
      questions: [],
    };
  },
  computed: {
    total() {
      let sum = 0;
      this.questions.forEach((elem) => (sum += +elem["marks"]))("Sum " + sum);
      return sum;
    },
  },
  methods: {
    redirect() {
      this.$router.push({ name: "Home" });
    },
    init() {
      (this.edit_open = false),
        (this.mcq = true),
        (this.current_question = ""),
        (this.current_options = ["", "", "", ""]),
        (this.correct_option = ""),
        (this.answer = "");
      this.marks = -1;
    },
    showAnswer(index, event) {
      if (event.target.innerText == "Show Answer")
        event.target.innerText = "Hide Answer";
      else event.target.innerText = "Show Answer";

      if (this.questions[index].propositions == null)
        $("#answer" + index).toggle();
      else {
        let li = document
          .querySelectorAll(".prev-made-questions > ol > li")
          [index].querySelectorAll("li");

        for (let i = 0; i < li.length; i++) {
          if (li[i].innerText == this.questions[index].correct_option) {
            // console.log(li[i]);
            if (li[i].style.color == "rgb(0, 245, 0)")
              li[i].style.color = "black";
            else li[i].style.color = "rgb(0, 245, 0)";
            // console.log(li[i].innerText);
          }
        }
      }
    },
  },
};
</script>
<style scoped>
.question {
  font-size: 20;
  font-weight: 600;
}
* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  letter-spacing: 2px;
}
.editable-ques {
  width: 60%;
  margin-top: 20px;
}
.header-quiz {
  display: flex;
  width: 100%;
  height: 20%;
  border-bottom: 1px solid #e7eae0;
  justify-content: center;
  align-items: center;
  background-color: #e7eae0;
  border-radius: 10px 10px 0px 0px;
}

.container-app {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
}
.container-quiz {
  display: flex;
  width: 40%;
  height: 85%;
  background-color: white;
  text-align: center;
  flex-flow: column;
  border: 1px solid #e7eae0;
  border-radius: 10px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  align-items: center;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.questions {
  width: 100%;
}
</style>
